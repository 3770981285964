* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.showcase {
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:black;
    color: white;
    z-index: 2;
}

.showcase header{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    padding: 40px 100px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggle {
    position: relative;
    width: 60px;
    height: 60px;
/*    background: url(''); */
    background-position: center;
    cursor: pointer;
}

.toggle.active{

}

.menu{
    
}

.showcase video {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit:cover;
    opacity: 0.8;
}

.text{
    position: relative;
    z-index: 10;
}

.overlay{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:#03a9f4;
    mix-blend-mode: overlay;
}

.text h2 {
    font-size: 5em;
    font-weight: bold;
}